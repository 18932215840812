<template>
  <div class="wrap">
    <CancelModal v-if="cancelStatus" />
    <!-- 중간 저장 팝업1 : 카드등록X AND 체험상태  -->
    <CommonConfirmVertical
      v-if="cancelCheckStatus && !isRegistCard && trialStatus"
      @confirm-yes="cancelCheckStatus = false"
      @confirm-no="onCancelCheckStatusConfirmYes"
    >
      <template #contents>
        <h4 class="common_alertbox_contents">
          거의 다왔어요! <br />
          그래도 그만하시겠어요?
        </h4>
      </template>
      <template #confirmyes>
        그렇다면 계속 할래요
      </template>
      <template #confirmno>
        나중에 할래요
      </template>
    </CommonConfirmVertical>
    <!-- 중간 저장 팝업2 : 나머지경우  -->
    <CommonConfirm
      v-else-if="cancelCheckStatus"
      :confirmid="'cancelcheckmodal'"
      @close-modal="onCancelCheckStatusConfirmNo"
      @confirm-modal="onCancelCheckStatusConfirmYes"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h3 class="stopmodal_text1">
            등록중인 스페이스를 저장할까요?
          </h3>
        </div>
      </template>
    </CommonConfirm>
    <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
    <!-- ################### PC ################### -->
    <div class="space_pc">
      <section class="main registration">
        <div class="main_box my_space3">
          <div class="main_box_title">
            <h1 class="spaceinsert_title">스페이스 등록</h1>
            <div @click="linkMySpace()">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div class="cs_main">
            <div class="myspace_content qration">
              <div class="form_text">
                <h3 class="spaceinsert_subtitle">매장 인테리어의 주요 색상을 선택해주세요.</h3>
              </div>
              <div class="pagnation">
                <span></span>
                <span style="width: 24%"></span>
              </div>
            </div>
            <ul class="spaceinsert_color_container three_type fade-up">
              <li class="spaceinsert_color_item" v-for="item in pcGetColors1" :key="item.no">
                <label class="spaceinsert_color_item_wrap cursor" :for="`image${item.no}`">
                  <img class="spaceinsert_color_img" :src="item.img" />
                </label>
                <div class="active_circle" v-if="selectColor === item.no">
                  <svg
                    data-v-64b02094=""
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-64b02094=""
                      d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <input :id="`image${item.no}`" type="radio" hidden :value="item.no" v-model="selectColor" />
              </li>
            </ul>
            <ul class="spaceinsert_color_container second three_type fade-up">
              <li class="spaceinsert_color_item" v-for="item in pcGetColors2" :key="item.no">
                <label class="spaceinsert_color_item_wrap cursor" :for="`image${item.no}`">
                  <img class="spaceinsert_color_img" :src="item.img" alt="14" />
                </label>
                <div class="active_circle" v-if="selectColor === item.no">
                  <svg
                    data-v-64b02094=""
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-64b02094=""
                      d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <input :id="`image${item.no}`" type="radio" hidden :value="item.no" v-model="selectColor" />
              </li>
            </ul>
          </div>
          <div class="form_bottom space_1">
            <div class="form_bottom_box">
              <div></div>
              <div>
                <button id="prev_btn" @click="clickPre()">이전</button>
                <button class="space_next_btn" id="next_btn" @click="updateCuration" :disabled="btnDisabled">
                  다음
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- ################### 모바일 ################### -->
    <div class="space_mo h_100">
      <div class="mo_space_header">
        <div class="mo_header_box">
          <a>
            <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
          </a>
          <h2>스페이스 등록</h2>
          <div class="btn_close" @click="linkMySpace()">
            <img src="/media/images/icon_close_24.svg" />
          </div>
        </div>
        <div class="pagnation">
          <span></span>
          <span style="width: 24%"></span>
        </div>
      </div>
      <section class="main myspace">
        <div class="main_box">
          <div class="myspace_content_form">
            <div class="form_text">
              <h3 class="spaceinsert_subtitle">내 매장 분위기 하나를 골라주세요.</h3>
            </div>
            <ul class="spaceinsert_color_container three_type fade-up">
              <li class="spaceinsert_color_item" v-for="item in moGetColors1" :key="item.no">
                <label class="spaceinsert_color_item_wrap cursor" :for="`image${item.no}`">
                  <img class="spaceinsert_color_img" :src="item.img" alt="14" />
                </label>
                <div class="active_circle" v-if="selectColor === item.no">
                  <svg
                    class="active_circle_svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-64b02094=""
                      d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <input :id="`image${item.no}`" type="radio" hidden :value="item.no" v-model="selectColor" />
              </li>
            </ul>

            <ul class="spaceinsert_color_container second three_type fade-up">
              <li class="spaceinsert_color_item" v-for="item in moGetColors2" :key="item.no">
                <label class="spaceinsert_color_item_wrap cursor" :for="`image${item.no}`">
                  <img class="spaceinsert_color_img" :src="item.img" alt="14" />
                </label>
                <div class="active_circle" v-if="selectColor === item.no">
                  <svg
                    class="active_circle_svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-64b02094=""
                      d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <input :id="`image${item.no}`" type="radio" hidden :value="item.no" v-model="selectColor" />
              </li>
            </ul>

            <ul class="spaceinsert_color_container second three_type fade-up">
              <li class="spaceinsert_color_item" v-for="item in moGetColors3" :key="item.no">
                <label class="spaceinsert_color_item_wrap cursor" :for="`image${item.no}`">
                  <img class="spaceinsert_color_img" :src="item.img" alt="14" />
                </label>
                <div class="active_circle" v-if="selectColor === item.no">
                  <svg
                    class="active_circle_svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-64b02094=""
                      d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <input :id="`image${item.no}`" type="radio" hidden :value="item.no" v-model="selectColor" />
              </li>
            </ul>

            <ul class="spaceinsert_color_container second three_type fade-up">
              <li class="spaceinsert_color_item" v-for="item in moGetColors4" :key="item.no">
                <label class="spaceinsert_color_item_wrap cursor" :for="`image${item.no}`">
                  <img class="spaceinsert_color_img" :src="item.img" alt="14" />
                </label>
                <div class="active_circle" v-if="selectColor === item.no">
                  <svg
                    class="active_circle_svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-64b02094=""
                      d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <input :id="`image${item.no}`" type="radio" hidden :value="item.no" v-model="selectColor" />
              </li>
            </ul>
          </div>
          <div class="form_bottom">
            <button class="space_next_btn" id="next_btn" @click="updateCuration" :disabled="btnDisabled || btnLoading">
              다음
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import CommonConfirm from '@/components/common/CommonConfirm.vue';
import CommonConfirmVertical from '@/components/common/CommonConfirmVertical.vue';
import {
  getColorList,
  setSpaceColor,
  getSpaceCurationInfo,
  checkSubscribe,
  updateConfirmCuration
} from '@/service/api/profileApi';
export default {
  props: {
    tempsavecolor: {
      type: Function,
      required: false,
      default: null
    },
    linkmyspace: {
      type: Function,
      required: false,
      default: null
    }
  },
  name: 'SpaceInsertColor',
  components: {
    CancelModal,
    CommonConfirmVertical,
    CommonConfirm,
    'popup-modal': PopUpModal
  },
  data() {
    return {
      cancelStatus: false,
      cancelCheckStatus: false,
      isRegistCard: true,
      isPopUpModal: false,
      popupModalText: '',
      curationInfo: {},
      spaceInfo: null,
      selectColor: null,
      spaceId: localStorage.getItem('spaceId'),
      btnLoading: false,
      curationId: ''
    };
  },

  created() {
    const aToken = this.$cookies.get('aToken');
    const headers = { 'X-AUTH-TOKEN': aToken };
    getSpaceCurationInfo(this.spaceId, headers)
      .then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.curationId = result.curationId;

          if (result.color) {
            const colorFind = this.$store.getters['curation/getColorItem'](result.color);
            if (Object.keys(colorFind).length > 0) {
              this.selectColor = colorFind.no;
            } else {
              this.selectColor = null;
            }
          } else {
            this.selectColor = null;
          }
        } else {
          this.selectColor = null;
        }
      })
      .catch(() => {
        this.selectColor = null;
      });

    getColorList()
      .then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.$store.commit('curation/setCurationColors', result);
        } else {
          this.$store.commit('curation/setCurationColors', []);
        }
      })
      .catch(() => {
        this.$store.commit('curation/setCurationColors', []);
      });
  },

  mounted() {
    document.querySelector('html').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },

  methods: {
    clickPre() {
      this.$router.push({ name: 'SpaceInsertStore' });
    },
    onCloseModal() {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    onDeleteSpace(msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    linkMySpace() {
      const spaceId = localStorage.getItem('spaceId');
      checkSubscribe(spaceId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          if (result === 'S') {
            this.cancelCheckStatus = true;
          } else {
            this.$router.push({ name: 'Player' }).catch(() => {});
          }
        }
      });
    },
    async updateCuration() {
      this.btnLoading = true;
      try {
        const { data: spaceData } = await setSpaceColor(this.spaceId, this.selectColor);
        const { resultCd: spaceResultCd } = spaceData;
        if (spaceResultCd === '0000') {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationInfo.curationId);
          const { resultCd: confirmCurationResultCd } = confirmCurationData;
          if (confirmCurationResultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStoreMaterial' }).catch(() => {});
          }
        }
      } finally {
        this.btnLoading = false;
      }
    },
    /**
     * @description 임시저장 팝업 아니오 클릭
     */
    async onCancelCheckStatusConfirmNo() {
      this.linkmyspace(this.spaceId);
    },
    /**
     * @description 임시저장 팝업 예 클릭
     */
    async onCancelCheckStatusConfirmYes() {
      // 색상 로직
      const result = await this.tempsavecolor(this.spaceId, this.selectColor);

      if (result) {
        this.cancelCheckStatus = false;
        this.cancelStatus = true;
      }
    }
  },

  computed: {
    pcGetColors1() {
      return this.$store.getters['curation/getCurationColors'].slice(0, 8);
    },
    pcGetColors2() {
      return this.$store.getters['curation/getCurationColors'].slice(8, 16);
    },
    moGetColors1() {
      return this.$store.getters['curation/getCurationColors'].slice(0, 5);
    },
    moGetColors2() {
      return this.$store.getters['curation/getCurationColors'].slice(5, 10);
    },
    moGetColors3() {
      return this.$store.getters['curation/getCurationColors'].slice(10, 15);
    },
    moGetColors4() {
      return this.$store.getters['curation/getCurationColors'].slice(15);
    },
    btnDisabled() {
      if (this.selectColor !== null) {
        return false;
      }

      return true;
    },
    trialStatus() {
      return this.$store.state.trialStatus;
    }
  }
};
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped src="@/assets/css/spacemanage/spaceinsert.css"></style>
<style scoped src="@/assets/css/modal/space/playstop.css"></style>
